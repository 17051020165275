import React from "react";
import Model from "../Model";
import styles from "./styles.module.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
const TransactionModel = (props) => {
  return (
    <Model
      visible={props.visible}
      title={props.tx_title}
      className={`${styles.wallet_model}`}
      onHide={props.onHide}
    >
      <p className={styles.text}>
        {props.title1 ? props.title1 : "Please check your wallet to allow the transaction."}
      </p>
      <p className={styles.text}>
        {props.title2 ? props.title2 : "Please sign transaction when asked."}
      </p>
      <br/><br/>
      <div style={{textAlign: "center"}}>
      <Loader
        type="ThreeDots"
        color="#7557BB"
        height={50}
        width={50}
        timeout={3000000} //3 secs
      />
      </div>
    </Model>
  );
};

export default TransactionModel;
